<template>
  <v-container>
    <v-loading v-model="loading">
        <v-list-item class="px-2">
        <v-list-item-content>
          <v-list-item-title class="title">
            Aplicadores
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn fab depressed small color="primary" @click="onCreate">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-card outlined>
        <v-simple-table>
          <thead>
            <tr class="text-uppercase">
              <th colspan="100">Nome</th>
            </tr>  
          </thead>    
          <tbody>
            <template v-for="therapist in therapists">
              <tr :key="therapist.id">
                <td>{{ therapist.name }}</td>
                <td class="text-right">
                  <v-btn icon @click="onEdit(therapist)">
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                  <v-btn icon :loading="deleting === therapist.id" :disabled="deleting === therapist.id" @click="onDelete(therapist)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card>
    </v-loading>
    <EditTherapistDialog
      :therapist="therapist"
      v-model="editTherapistDialog"
    />
    <CreateTherapistDialog
      v-model="createTherapistDialog"
    />
  </v-container>
</template>

<script>
  import UserService from '../../services/user'
  import TherapistService from '../../services/therapist'
  import EditTherapistDialog from '../../dialogs/EditTherapistDialog'
  import CreateTherapistDialog from '../../dialogs/CreateTherapistDialog'

  export default {
    components: {
      EditTherapistDialog,
      CreateTherapistDialog
    },
    data: () => ({
      loading: false,
      deleting: null,
      editTherapistDialog: false,
      createTherapistDialog: false,
      therapist: {},
      therapists: [],
    }),
    watch: {
      editTherapistDialog(value) {
        if (!value) {
          this.fetchStart()
        }
      },
      createTherapistDialog(value) {
        if (!value) {
          this.fetchStart()
        }
      }
    },
    methods: {
      onEdit(therapist) {
        this.therapist = therapist
        this.editTherapistDialog = true
      },
      onCreate() {
        this.createTherapistDialog = true
      },
      onDelete(therapist) {
        if (confirm('Deseja realmente remover este Aplicador?')) {  
          this.deleting = therapist.id;
          TherapistService.remove({
            id: therapist.id,
          }).then(() => {
            this.fetchStart();
          }).catch(() => alert('Não foi possível remover o Aplicador!'))
          .then(() => {
            this.deleting = null;    
          });
        }
      },
      fetchStart() {
        this.loading = true

        UserService.therapists()
          .then((data) => {
            this.therapists = data
            this.loading = false
          })
      }
    },
    mounted() {
      this.fetchStart()
    }
  }
</script>
