import http from './http';

const view = (id) => {
  return http.get('therapists/view', {
    params: {id}
  });
};

const create = (data) => {
  return http.post('therapists/create', data);
};

const update = (data) => {
  return http.post('therapists/update', data);
};

const remove = (data) => {
  return http.post('therapists/delete', data);
};

export default { view, create, update, remove };